<template>
  <div>
    <b-row>
      <b-col
        v-if="!editFormSP"
        sm="12"
      >
        <span class="float-right mt-1">
          {{ $t( 'buttons.edit') }}
          <b-button
            class="btn-icon rounded-circle"
            variant="secondary"
            @click="editFormSP = true"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </span>
      </b-col>
    </b-row>
    <validation-observer ref="passwordValidation">
      <!-- form -->
      <b-form @submit.prevent="savePassword">
        <b-row>
          <!-- old password -->
          <b-col
            sm="6"
            md="4"
          >
            <b-form-group label-for="account-old-password">
              {{ $t('accountSettings.oldPassword') }}
              <span class="text-danger">*</span>
              <validation-provider
                #default="{ errors }"
                name="oldPassword"
                rules="min:8|required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    :type="passwordFieldTypeOld"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="old-password"
                    :placeholder="$t('accountSettings.oldPassword')"
                    :disabled="!editFormSP"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->

          <!-- new password -->
          <b-col
            sm="6"
            md="4"
          >
            <b-form-group label-for="account-new-password">
              {{ $t('accountSettings.newPassword') }}
              <span class="text-danger">*</span>
              <validation-provider
                #default="{ errors }"
                name="newPassword"
                rules="min:8|required|password|confirmPassword:@newPasswordRet"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="new-password"
                    :placeholder="$t('accountSettings.newPassword')"
                    :disabled="!editFormSP"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col
            sm="6"
            md="4"
          >
            <b-form-group label-for="account-retype">
              {{ $t('accountSettings.retypeNewPassword') }}
              <span class="text-danger">*</span>
              <validation-provider
                #default="{ errors }"
                name="newPasswordRet"
                rules="min:8|required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-retype"
                    v-model="retypePassword"
                    :type="passwordFieldTypeRetype"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="retype-password"
                    :placeholder="$t('accountSettings.newPassword')"
                    :disabled="!editFormSP"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <hr>
            <b-button
              v-if="editFormSP"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              pill
              variant="primary"
              class="ml-1 float-right"
            >
              {{ $t('buttons.save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="secondary"
              pill
              class="float-right"
              @click="editFormSP ? reassignAux() : $router.push({ name: 'dashboard-main'})"
            >
              {{ editFormSP ? $t('buttons.cancel') : $t('buttons.back') }}
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters, mapActions } from 'vuex'
import { initialAbility } from '@/libs/acl/config'
import { BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, password, min, confirmPassword } from '@validations'
import userService from '@/services/userService'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      editFormSP: false,
      passwordValueOld: '',
      newPasswordValue: '',
      retypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required, // validation
      password, // validation
      min, // validation
      confirmPassword, // validation
    }
  },

  computed: {
    ...mapGetters({ updatedPassword: 'updatedPassword' }),

    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  mounted() {
    if (!this.updatedPassword) {
      this.showIncompleteDataMessage()
      this.editFormSP = true
    }
  },

  methods: {
    ...mapActions([
      'setInfo',
      'setColorValueSidebar',
      'setColorValue',
      'deleteSessionInfo',
      'setCurrentClub',
      'setClubsByUser',
    ]),

    resetPassword() {
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.retypePassword = ''
      this.$refs.passwordValidation.reset()
    },

    reassignAux() {
      this.resetPassword()
      this.editFormSP = false
    },

    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },

    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },

    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    savePassword() {
      this.$refs.passwordValidation.validate().then(success => {
        if (success) {
          const passwordData = {
            user_id: this.$route.params.id,
            old_password: this.passwordValueOld,
            new_password: this.newPasswordValue,
            new_password_confirmation: this.retypePassword,
          }

          userService.setNewPassword(passwordData).then(({ data }) => {
            this.resetPassword()
            this.refreshDataUser()
            this.showSuccessMessage()

            setTimeout(() => {
              this.logout()
            }, 2000)
          }).catch(error => {
            this.responseCatch(error)
          })
        }
      })
    },

    refreshDataUser() {
      const data = { locale: null }

      userService.getUserAuthenticated(data).then(response => {
        this.setInfo({ user: response.data.data, permissions: null })
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    showSuccessMessage() {
      this.$swal({
        title: '¡Éxito!',
        text: 'La información se guardo correctamente',
        icon: 'success',
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: `${this.$t('generic.accept')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })

      this.editFormSP = false
    },

    showIncompleteDataMessage() {
      this.$swal({
        title: '¡No has actualizado tu contraseña!',
        text: 'Para poder continuar es necesario, actualizar tu contraseña.',
        icon: 'warning',
        timer: 5000,
        showConfirmButton: true,
        confirmButtonText: `${this.$t('generic.accept')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    logout() {
      this.$ability.update(initialAbility)
      this.deleteSessionInfo()
      this.setCurrentClub({})
      this.setClubsByUser([])

      setTimeout(() => {
        sessionStorage.removeItem('web-page')
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')

        localStorage.removeItem('vuex')
        localStorage.clear()

        // Reset ability

        this.setColorValueSidebar('#33334d')
        this.setColorValue('#6abeff')

        // Redirect to login page
        this.$router.replace({ name: 'auth-login' })
      }, 200)
    },
  },
}
</script>
